import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { css } from "aphrodite";
import PropTypes from "prop-types";
import { memo } from "react";
import { Link } from "react-router-dom";

import { useStyles } from "hooks/useStyles";

import colours from "styles/colours";
import gStyles from "styles/GenericStyles";
import getHoverQuery from "styles/getHoverQuery";

const baseStyles = {
  categories: {
    marginBottom: "1rem",
  },
  category: {
    display: "inline-block",
  },
  inlineCategory: {
    marginRight: "0.5em",
    marginBottom: "0.5rem",
  },
  categoryText: {
    ...gStyles.fontSemiBold,
    display: "inline-block",
    background: "#edf1f4",
    color: "#5F6D95",
    borderRadius: "4em",
    padding: "0.4em 0.8em 0.3em",
    fontSize: "0.7em",

    ...getHoverQuery({
      background: "#d6dadd",
      color: colours.bodyText,
    }),
  },
  icon: {
    marginRight: "0.4em",
    height: "11.89px",
  },
};

function BlogCategoryLink(props) {
  const { styles } = useStyles(baseStyles, props);
  const { id, dataId, name, link, inline, icon } = props;

  const UseComponent = link ? Link : "div";

  return (
    <li
      data-testid="list"
      className={css(styles.category, inline && styles.inlineCategory)}
      key={id}
    >
      <UseComponent
        data-id={dataId || id}
        to={link}
        className={css(styles.categoryText)}
        data-testid="mainComponent"
      >
        {icon && (
          <FontAwesomeIcon
            data-testid="blogIcon"
            icon={icon}
            className={css(styles.icon)}
          />
        )}
        {name}
      </UseComponent>
    </li>
  );
}

BlogCategoryLink.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  dataId: PropTypes.string,
  name: PropTypes.node,
  link: PropTypes.string,
  inline: PropTypes.bool,
  icon: PropTypes.object,
};

BlogCategoryLink.defaultProps = {
  dataId: null,
  name: null,
  link: "",
  inline: false,
  icon: null,
};

export default memo(BlogCategoryLink);
